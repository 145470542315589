import React, { useContext, useEffect } from 'react'
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import RangeContext from '../contexts/RangeContext'
import cevRanges from '../data/cEV_Ranges.json'
import cevFrequencies from '../data/cEV_Frequencies.json'
import quarterCCLRanges from '../data/ICM_25pct_250pc_Ranges.json'
import quarterCCLFrequencies from '../data/ICM_25pct_250pc_Frequencies.json'
import halfCCLRanges from '../data/ICM_50pct_250pc_Ranges.json'
import halfCCLFrequencies from '../data/ICM_50pct_250pc_Frequencies.json'
import threeQuarterCCLRanges from '../data/ICM_75pct_250pc_Ranges.json'
import threeQuarterCCLFrequencies from '../data/ICM_75pct_250pc_Frequencies.json'

const useStyles = makeStyles((theme) => ({
  doesNotExist: {
    color: 'rgb(181, 134, 22)',
    width: '30vw',
    height: '30vw',
    textAlign: 'center',
    fontSize: 40,
    top: '50%',
    left: '50%',
    transform: 'translateY(50%)',
  },
  foldCell: {
    textAlign: 'center',
    width: '2.5vw',
    height: '2.5vw',
    lineHeight: 'max(32.5px, 2.5vw)',
    minWidth: '32.5px',
    minHeight: '32.5px',
  },
  notInRangeCell: {
    background: 'rgb(255, 255, 255, 0.15)',
    textAlign: 'center',
    width: '2.5vw',
    height: '2.5vw',
    lineHeight: 'max(32.5px, 2.5vw)',
    minWidth: '32.5px',
    minHeight: '32.5px',
  },
  gridWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(13, minmax(32.5px, 2.5vw))',
    gridTemplateRows: 'repeat(13, minmax(32.5px, 2.5vw))',
    columnGap: '2px',
    rowGap: '2px',
    width: '75vw',
    justifyContent: 'center',
    paddingTop: '7vh',
    minWidth: '730px',
  },
  raiseCell: {
    background: 'rgb(217, 160, 102)',
    width: '2.5vw',
    height: '2.5vw',
    lineHeight: 'max(32.5px, 2.5vw)',
    textAlign: 'center',
    minWidth: '32.5px',
    minHeight: '32.5px',
  },
  jamCell: {
    background: 'rgb(217, 87, 99)',
    width: '2.5vw',
    height: '2.5vw',
    lineHeight: 'max(32.5px, 2.5vw)',
    textAlign: 'center',
    minWidth: '32.5px',
    minHeight: '32.5px',
  },
  callCell: {
    background: 'rgb(251, 242, 54)',
    width: '2.5vw',
    height: '2.5vw',
    lineHeight: 'max(32.5px, 2.5vw)',
    textAlign: 'center',
    minWidth: '32.5px',
    minHeight: '32.5px',
  },
  gradientCell: {
    width: '2.5vw',
    height: '2.5vw',
    lineHeight: 'max(32.5px, 2.5vw)',
    textAlign: 'center',
    minWidth: '32.5px',
    minHeight: '32.5px',
  },
}))

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
  },
})

export default function Viewer() {
  const [context, setContext] = useContext(RangeContext)

  const classes = useStyles()
  let callFrequency, raiseFrequency, jamFrequency, foldFrequency
  let range = {}
  let frequencies = {}
  let openingRange = {}
  let openingRangeFrequencies = {}
  let totalOpeningHands = context.totalStartingHands

  let ranges, rangeFrequencies

  switch (context.fieldSize) {
    case 'cEV':
      ranges = cevRanges
      rangeFrequencies = cevFrequencies
      break
    case '25pct':
      ranges = quarterCCLRanges
      rangeFrequencies = quarterCCLFrequencies
      break
    case '50pct':
      ranges = halfCCLRanges
      rangeFrequencies = halfCCLFrequencies
      break
    case '75pct':
      ranges = threeQuarterCCLRanges
      rangeFrequencies = threeQuarterCCLFrequencies
      break
    default:
      ranges = cevRanges
      rangeFrequencies = cevFrequencies
      break
  }

  // 'SB vs BB raise'      -> limp -> raise                                               DONE
  // 'BB vs SB 3-bet'      -> limp -> raise -> raise                                      DONE
  // 'SB vs BB 4-bet'      -> limp -> raise -> raise -> raise                             DONE
  // 'BB vs SB 5-bet jam'  -> limp -> raise -> raise -> raise -> jam                      DONE
  // 'SB vs BB 3-bet'      -> raise -> raise                                              DONE
  // 'BB vs SB 4-bet'      -> raise -> raise -> raise                                     DONE
  // 'SB vs BB 3-bet jam'  -> raise -> jam                                                DONE
  // 'SB vs BB 5-bet jam'  -> raise -> raise -> raise -> jam                              DONE
  // 'SB vs BB 4-bet jam'  -> limp -> raise -> raise -> jam                               DONE
  // 'BB vs SB 3-bet jam'  -> limp -> raise -> jam                                        DONE
  // 'BB vs SB 4-bet jam'  -> raise -> raise -> jam                                       DONE
  // 'SB vs BB jam'        -> limp -> jam                                                 DONE
  // 'BB vs SB limp'       -> limp                                                        DONE
  // 'BB vs SB raise'      -> raise                                                       DONE
  // 'BB vs SB jam'        -> jam                                                         DONE


  // TODO: Get rid of all those null checks that Rod put in there

  const bvbSpots = {
    none: 'BB vs SB limp',
    call: {
      none: 'BB vs SB limp',
      raise: {
        none: 'SB vs BB raise',
        raise: {
          none: 'BB vs SB 3-bet',
          raise: { none: 'SB vs BB 4-bet', jam: 'BB vs SB 5-bet jam' },
          jam: { none: 'SB vs BB 4-bet jam' },
        },
        jam: { none: 'BB vs SB 3-bet jam' },
      },
      jam: { none: 'SB vs BB jam' },
    },
    raise: {
      none: 'BB vs SB raise',
      raise: {
        none: 'SB vs BB 3-bet',
        raise: {
          none: 'BB vs SB 4-bet',
          jam: { none: 'SB vs BB 5-bet jam' },
        },
        jam: { none: 'BB vs SB 4-bet jam' },
      },
      jam: { none: 'SB vs BB 3-bet jam' },
    },
    jam: { none: 'BB vs SB jam' },
  }

  const setFrequencies = (frequencies, totalHands) => {
    callFrequency = Math.round((frequencies.Call / totalHands + Number.EPSILON) * 1000) / 10
    raiseFrequency = Math.round((frequencies.Raise / totalHands + Number.EPSILON) * 1000) / 10
    jamFrequency = Math.round((frequencies.Jam / totalHands + Number.EPSILON) * 1000) / 10
    foldFrequency =
      Math.round((100 - callFrequency - raiseFrequency - jamFrequency + Number.EPSILON) * 10) / 10
  }

  const getBvBSpot = () => {
    let bvbSpot
    let rfiRange = context.type === 'HU' ? 'SB-HU' : 'SB'

    if (context.firstSBAction === 'none') {
      bvbSpot = bvbSpots[context.firstSBAction]
      openingRange = {}
      totalOpeningHands = 1326
    } else if (context.firstBBAction === 'none') {
      bvbSpot = bvbSpots[context.firstSBAction][context.firstBBAction]
      openingRange = {}
      totalOpeningHands = 1326
    } else if (context.secondSBAction === 'none') {
      bvbSpot = bvbSpots[context.firstSBAction][context.firstBBAction][context.secondSBAction]
      openingRange = ranges[context.type]?.[context.size]?.[rfiRange]
      openingRangeFrequencies = rangeFrequencies[context.type]?.[context.size]?.[rfiRange]
      if (context.firstSBAction === 'call') {
        totalOpeningHands = openingRangeFrequencies?.['Call']
      } else {
        totalOpeningHands = openingRangeFrequencies?.['Raise']
      }
    } else if (context.secondBBAction === 'none') {
      bvbSpot =
        bvbSpots[context.firstSBAction][context.firstBBAction][context.secondSBAction][
          context.secondBBAction
        ]
      openingRange = ranges[context.type][context.size]?.[bvbSpots[context.firstSBAction]?.['none']]
      openingRangeFrequencies =
        rangeFrequencies[context.type][context.size]?.[bvbSpots[context.firstSBAction]?.['none']]
      totalOpeningHands = openingRangeFrequencies?.Raise
    } else if (context.thirdSBAction === 'none') {
      bvbSpot =
        bvbSpots[context.firstSBAction]?.[context.firstBBAction]?.[context.secondSBAction]?.[
          context.secondBBAction
        ]?.[context.thirdSBAction]
      openingRange =
        ranges[context.type][context.size][
          bvbSpots[context.firstSBAction]?.[context.firstBBAction]?.['none']
        ]
      openingRangeFrequencies =
        rangeFrequencies[context.type][context.size][
          bvbSpots[context.firstSBAction]?.[context.firstBBAction]?.['none']
        ]
      totalOpeningHands = openingRangeFrequencies?.Raise
    } else {
      bvbSpot =
        bvbSpots[context.firstSBAction][context.firstBBAction]?.[context.secondSBAction][
          context.secondBBAction
        ][context.thirdSBAction]
      openingRange =
        ranges[context.type][context.size][
          bvbSpots[context.firstSBAction][context.firstBBAction]?.[context.secondSBAction]?.['none']
        ]
      openingRangeFrequencies =
        rangeFrequencies[context.type][context.size][
          bvbSpots[context.firstSBAction][context.firstBBAction]?.[context.secondSBAction]?.['none']
        ]
      totalOpeningHands = openingRangeFrequencies?.Raise
    }

    return bvbSpot
  }

  switch (context.type) {
    case 'RFI':
      range = ranges[context.type][context.size]?.[context.hpos]
      frequencies = rangeFrequencies[context.type][context.size]?.[context.hpos]
      break
    case 'vsOpen':
    case 'vsJam':
      range = ranges[context.type][context.size]?.[context.hpos][context.vpos]
      frequencies = rangeFrequencies[context.type][context.size]?.[context.hpos][context.vpos]
      break
    case 'vs3bet':
    case 'vs3betJam':
      range = ranges[context.type][context.size]?.[context.hpos][context.vpos]
      frequencies = rangeFrequencies[context.type][context.size]?.[context.hpos][context.vpos]
      openingRange = ranges['RFI'][context.size]?.[context.hpos]
      openingRangeFrequencies = rangeFrequencies['RFI'][context.size]?.[context.hpos]
      totalOpeningHands = openingRangeFrequencies?.Raise
      break
    case 'vs4bet':
    case 'vs4betJam':
      range = ranges[context.type][context.size]?.[context.hpos][context.vpos]
      frequencies = rangeFrequencies[context.type]?.[context.size]?.[context.hpos][context.vpos]
      openingRange = ranges['vsOpen'][context.size]?.[context.hpos]?.[context.vpos]
      openingRangeFrequencies = rangeFrequencies['vsOpen'][context.size]?.[context.hpos]?.[context.vpos]
      totalOpeningHands = openingRangeFrequencies?.Raise
      break
    case 'squeeze':
      range = ranges[context.type][context.size]?.[context.hpos][context.vpos][context.v2pos]
      frequencies =
        rangeFrequencies[context.type][context.size]?.[context.hpos][context.vpos][context.v2pos]
      break
    case 'vsSqueeze':
    case 'vsSqueezeJam':
      range = ranges[context.type][context.size]?.[context.hpos][context.vpos][context.v2pos]
      frequencies =
        rangeFrequencies[context.type][context.size]?.[context.hpos][context.vpos][context.v2pos]
      openingRange = ranges['RFI'][context.size]?.[context.hpos]
      openingRangeFrequencies = rangeFrequencies['RFI'][context.size]?.[context.hpos]
      totalOpeningHands = openingRangeFrequencies?.Raise
      break
    case 'bvb':
      if (context.firstSBAction === 'none') {
        range = ranges[context.type]?.[context.size]?.['SB']
        frequencies = rangeFrequencies[context.type]?.[context.size]?.['SB']
      } else {
        let bvbSpot = getBvBSpot()

        range = ranges[context.type][context.size]?.[bvbSpot]
        frequencies = rangeFrequencies[context.type]?.[context.size]?.[bvbSpot]
      }
      break
    case 'HU':
      ranges = cevRanges
      rangeFrequencies = cevFrequencies

      if (context.firstSBAction === 'none') {
        range = ranges[context.type]?.[context.size]['SB-HU']
        frequencies = rangeFrequencies[context.type]?.[context.size]['SB-HU']
      } else {
        let bvbSpot = getBvBSpot()

        range = ranges[context.type]?.[context.size][bvbSpot]
        frequencies = rangeFrequencies[context.type]?.[context.size][bvbSpot]
      }
      break
    default:
      range = ranges[context.type]?.[context.size][context.hpos]
  }

  useEffect(() => {
    let rangeHasCall,
      rangeHasJam,
      rangeHasRaise = false

    if (range) {
      for (var i = 0; i < range.length; i++) {
        if ('Call' in range[i]) rangeHasCall = true
        if ('Raise' in range[i]) rangeHasRaise = true
        if ('Jam' in range[i]) rangeHasJam = true

        if (rangeHasJam && rangeHasRaise && rangeHasCall) break
      }
    }

    if (frequencies) {
      setFrequencies(frequencies, totalOpeningHands)
    }

    setContext({
      ...context,
      rangeHasRaise,
      rangeHasJam,
      rangeHasCall,
      callFrequency,
      raiseFrequency,
      jamFrequency,
      foldFrequency,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context.type,
    context.size,
    context.hpos,
    context.vpos,
    context.v2pos,
    context.firstSBAction,
    context.firstBBAction,
    context.secondSBAction,
    context.secondBBAction,
    context.thirdSBAction,
    context.fieldSize,
  ])

  const createTable = (cells, openingRange) => {
    if (cells) {
      return cells.map((cell) => {
        return createCell(cell, openingRange)
      })
    } else {
      return <div className={classes.doesNotExist}> SOLUTION DOES NOT EXIST </div>
    }
  }

  const createCell = (cell, openingRange) => {
    let call = cell.Call > 0 ? cell.Call : 0
    let raise = cell.Raise > 0 ? cell.Raise : 0
    let jam = cell.Jam > 0 ? cell.Jam : 0
    let fold = Math.round((1 - call - raise - jam) * 100) / 100
    fold = fold < 0 ? 0 : fold
    let openingFoldorJam = 0

    if (['vs3bet', 'vs3betJam', 'bvb', 'HU', 'vs4bet', 'vs4betJam', 'vsSqueeze', 'vsSqueezeJam'].indexOf(context.type) > -1) {
      if (Object.keys(openingRange).length > 0) {
        let openingCell = openingRange.filter((obj) => {
          return obj.hand === cell.hand
        })[0]
        let openingCall = openingCell.Call > 0 ? openingCell.Call : 0
        let openingRaise = openingCell.Raise > 0 ? openingCell.Raise : 0

        if (
          context.firstSBAction === 'call' &&
          context.firstBBAction !== 'none' &&
          context.secondSBAction === 'none'
        ) {
          openingFoldorJam = Math.round((1 - openingCall) * 100) / 100
        } else {
          openingFoldorJam = Math.round((1 - openingRaise) * 100) / 100
        }
      }
    }

    let foldUpTo = fold * 100
    let callUpTo = foldUpTo + call * 100
    let jamUpTo = callUpTo + jam * 100

    let gradientTooltip = `${fold === 0 ? '' : 'Fold: ' + Math.round(fold * 100) + '% '}
                                ${call === 0 ? '' : 'Call: ' + Math.round(call * 100) + '% '}
                                ${jam === 0 ? '' : 'Jam: ' + Math.round(jam * 100) + '% '}
                                ${raise === 0 ? '' : 'Raise: ' + Math.round(raise * 100) + '%'}`

    if (openingFoldorJam > 0.96) {
      return (
        <Grid item key={cell.hand}>
          <Tooltip title="Not In Range" arrow>
            <Paper className={classes.notInRangeCell}>{`${cell.hand}`}</Paper>
          </Tooltip>
        </Grid>
      )
    }
    if (fold > 0.95) {
      return (
        <Grid item key={cell.hand}>
          <Tooltip title="Fold: 100%" arrow>
            <Paper className={classes.foldCell}>{`${cell.hand}`}</Paper>
          </Tooltip>
        </Grid>
      )
    }
    if (raise > 0.95) {
      return (
        <Grid item key={cell.hand}>
          <Tooltip title="Raise: 100%" arrow>
            <Paper className={classes.raiseCell}>{`${cell.hand}`}</Paper>
          </Tooltip>
        </Grid>
      )
    }
    if (jam > 0.95) {
      return (
        <Grid item key={cell.hand}>
          <Tooltip title="Jam: 100%" arrow>
            <Paper className={classes.jamCell}>{`${cell.hand}`}</Paper>
          </Tooltip>
        </Grid>
      )
    }
    if (call > 0.95) {
      return (
        <Grid item key={cell.hand}>
          <Tooltip title="Call: 100%" arrow>
            <Paper className={classes.callCell}>{`${cell.hand}`}</Paper>
          </Tooltip>
        </Grid>
      )
    }
    return (
      <Grid item key={cell.hand}>
        <Tooltip title={gradientTooltip} arrow>
          <Paper
            className={classes.gradientCell}
            style={{
              background: `linear-gradient(90deg, rgb(255, 255, 255) ${foldUpTo}%, rgb(251, 242, 54) ${foldUpTo}% ${callUpTo}%, rgb(217, 87, 99) ${callUpTo}% ${jamUpTo}%, rgb(217, 160, 102) ${jamUpTo}%)`,
            }}
          >
            {' '}
            {`${cell.hand}`}
          </Paper>
        </Tooltip>
      </Grid>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container item className={classes.gridWrapper} xs={12}>
        {createTable(range, openingRange)}
      </Grid>
    </MuiThemeProvider>
  )
}
