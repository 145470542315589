import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RangeContext from '../contexts/RangeContext'

const useStyles = makeStyles((theme) => ({
  selectedPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    boxShadow: 'inset 8px 8px 10px #946e12, inset -8px -8px 10px #d69e1a',
    background: 'rgb(181, 134, 22)',
    color: 'rgb(255, 255, 255)',
  },
  disabledPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
  },
  posButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    background: 'linear-gradient(145deg, #ffffff, #d6d6d6)',
    boxShadow: '5px 5px 10px #2b2b2b, -5px -5px 10px #434343',
    color: 'rgb(37, 37, 37)',
  },
  section: {
    paddingBottom: '2vh',
    paddingLeft: '0.5vw',
  },
}))

export default function BlindActions(props) {
  const [context, setContext] = useContext(RangeContext)

  const classes = useStyles()

  const { actionSequence, blindAction } = props

  const renderActionDisabled = (action) => {
    return (
      <Grid item>
        <Button disabled className={classes.disabledPosButton} variant="contained">
          {action}
        </Button>
      </Grid>
    )
  }

  const renderActionEnabled = (action, newContext = {}) => {
    let label
    newContext[blindAction] = action

    if (action === 'call') {
      label = 'Limp'
    } else {
      label = action
    }

    return (
      <Grid item key={action}>
        <Button
          className={
            context[blindAction] === action ? classes.selectedPosButton : classes.posButton
          }
          variant="contained"
          onClick={() => setContext({ ...context, ...newContext })}
        >
          {label}
        </Button>
      </Grid>
    )
  }

  const isDisabled = (action) => {
    let disabled = false

    const disabledActions = {
      bvb: {
        firstSBAction: {
          call: {
            cEV: {},
            '25pct': {},
            '50pct': {},
            '75pct': {},
            firstBBAction: {
              raise: {
                'BB2.5': true,
                BB5: true,
                cEV: {},
                '25pct': {},
                '50pct': {},
                '75pct': {},
                secondSBAction: {
                  raise: {
                    'BB7.5': true,
                    BB10: true,
                    'BB12.5': true,
                    BB15: true,
                    'BB17.5': true,
                    BB20: true,
                    BB25: true,
                    cEV: {},
                    '25pct': {},
                    '50pct': {},
                    '75pct': {},
                    secondBBAction: {
                      raise: {
                        BB30: true,
                        BB40: true,
                        BB50: true,
                        BB60: true,
                        BB80: true,
                        cEV: {},
                        '25pct': {},
                        '50pct': {},
                        '75pct': {},
                        thirdSBAction: {
                          jam: {
                            BB30: true,
                            BB50: true,
                            BB60: true,
                            BB100: true,
                            cEV: {},
                            '25pct': {},
                            '50pct': {},
                            '75pct': {},
                          },
                        },
                      },
                      jam: {
                        cEV: {},
                        '25pct': {},
                        '50pct': {},
                        '75pct': {}
                      },
                    },
                  },
                  jam: {
                    cEV: {},
                    '25pct': {},
                    '50pct': {},
                    '75pct': {}
                  },
                },
              },
              jam: {
                cEV: {},
                '25pct': {},
                '50pct': {},
                '75pct': {},
              },
            },
          },
          raise: {
            'BB2.5': true,
            BB5: true,
            cEV: {},
            '25pct': {},
            '50pct': {},
            '75pct': {},
            firstBBAction: {
              raise: {
                'BB7.5': true,
                BB10: true,
                'BB12.5': true,
                BB15: true,
                'BB17.5': true,
                BB20: true,
                cEV: {},
                '25pct': {},
                '50pct': {},
                '75pct': {},
                secondSBAction: {
                  raise: {
                    BB25: true,
                    BB40: true,
                    BB30: true,
                    BB50: true,
                    BB60: true,
                    cEV: {},
                    '25pct': {},
                    '50pct': {},
                    '75pct': {},
                    secondBBAction: {
                      raise: {
                        BB80: true,
                        BB100: true,
                        cEV: {},
                        '25pct': {},
                        '50pct': {},
                        '75pct': {},
                        thirdSBAction: {
                          jam: {
                            BB100: true,
                            cEV: {},
                            '25pct': {},
                            '50pct': {},
                            '75pct': {}
                          }
                        },
                      },
                      jam: { 
                        BB80: true,
                        cEV: {},
                        '25pct': {},
                        '50pct': {},
                        '75pct': {}
                      },
                    },
                  },
                  jam: {
                    //BB100: true,
                    cEV: {},
                    '25pct': {},
                    '50pct': {},
                    '75pct': {}
                  },
                },
              },
              jam: {
                cEV: {
                  'BB7.5': true,
                },
                '25pct': {},
                '50pct': {
                  'BB7.5': true,
                },
                '75pct': {
                  'BB7.5': true,
                },
              },
            },
          },
          jam: {
            cEV: {},
            '25pct': {},
            '50pct': {},
            '75pct': {},
          },
        },
      },
      HU: {
        firstSBAction: {
          call: {
            cEV: {},
            '25pct': {},
            '50pct': {},
            '75pct': {},
            firstBBAction: {
              raise: {
                BB10: true,
                cEV: {},
                '25pct': {},
                '50pct': {},
                '75pct': {},
                secondSBAction: {
                  raise: {
                    BB10: true,
                    'BB12.5': true,
                    BB15: true,
                    BB20: true,
                    BB25: true,
                    BB30: true,
                    cEV: {},
                    '25pct': {},
                    '50pct': {},
                    '75pct': {},
                    secondBBAction: {
                      raise: {
                        BB30: true,
                        BB40: true,
                        BB50: true,
                        BB60: true,
                        cEV: {},
                        '25pct': {},
                        '50pct': {},
                        '75pct': {},
                        thirdSBAction: {
                          jam: {
                            BB30: true,
                            BB50: true,
                            BB60: true,
                            cEV: {},
                            '25pct': {},
                            '50pct': {},
                            '75pct': {},
                          },
                        },
                      },
                      jam: { BB100: true, cEV: {}, '25pct': {}, '50pct': {}, '75pct': {} },
                    },
                  },
                  jam: {
                    BB40: true,
                    BB50: true,
                    BB60: true,
                    BB80: true,
                    BB100: true,
                    cEV: {},
                    '25pct': {},
                    '50pct': {},
                    '75pct': {},
                  },
                },
              },
              jam: {
                BB25: true,
                BB30: true,
                BB40: true,
                BB50: true,
                BB60: true,
                BB80: true,
                BB100: true,
                cEV: {},
                '25pct': {},
                '50pct': {},
                '75pct': {},
              },
            },
          },
          raise: {
            BB10: true,
            'BB12.5': true,
            cEV: {},
            '25pct': {},
            '50pct': {},
            '75pct': {},
            firstBBAction: {
              raise: {
                BB10: true,
                BB15: true,
                cEV: {},
                '25pct': {},
                '50pct': {},
                '75pct': {},
                secondSBAction: {
                  raise: {
                    BB20: true,
                    BB25: true,
                    BB30: true,
                    BB40: true,
                    cEV: {},
                    '25pct': {},
                    '50pct': {},
                    '75pct': {},
                    secondBBAction: {
                      raise: {
                        BB40: true,
                        BB50: true,
                        BB60: true,
                        BB80: true,
                        BB100: true,
                        cEV: {},
                        '25pct': {},
                        '50pct': {},
                        '75pct': {},
                        thirdSBAction: { jam: { BB100: true, cEV: {}, '25pct': {}, '50pct': {}, '75pct': {} } },
                      },
                      jam: { cEV: {}, '25pct': {}, '50pct': {}, '75pct': {} },
                    },
                  },
                  jam: { BB60: true, BB80: true, BB100: true, cEV: {}, '25pct': {}, '50pct': {}, '75pct': {} },
                },
              },
              jam: {
                BB30: true,
                BB40: true,
                BB50: true,
                BB60: true,
                BB80: true,
                BB100: true,
                cEV: {},
                '25pct': {},
                '50pct': {},
                '75pct': {},
              },
            },
          },
          jam: {
            BB20: true,
            BB25: true,
            BB30: true,
            BB40: true,
            BB50: true,
            BB60: true,
            BB80: true,
            BB100: true,
            cEV: {},
            '25pct': {},
            '50pct': {},
            '75pct': {},
          },
        },
      },
    }

    if (
      (actionSequence === 'firstBBActions' && context.firstSBAction === 'none') ||
      (actionSequence === 'secondSBActions' && context.firstBBAction === 'none') ||
      (actionSequence === 'secondBBActions' && context.secondSBAction === 'none') ||
      (actionSequence === 'thirdSBActions' && context.secondBBAction === 'none')
    ) {
      disabled = true
    } else if (action === 'none') {
      disabled = false
    } else if (
      ((actionSequence === 'firstBBActions' && context.firstSBAction === 'jam') ||
        (actionSequence === 'secondSBActions' && context.firstBBAction === 'jam') ||
        (actionSequence === 'secondBBActions' && context.secondSBAction === 'jam') ||
        (actionSequence === 'thirdSBActions' && context.secondBBAction === 'jam')) &&
      action !== 'none'
    ) {
      disabled = true
    } else if (actionSequence === 'firstSBActions') {
      if (
        disabledActions[context.type]['firstSBAction'][action][context.size] ||
        disabledActions[context.type]['firstSBAction'][action][context.fieldSize][context.size]
      ) {
        disabled = true
      }
    } else if (actionSequence === 'firstBBActions') {
      if (
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          action
        ][context.size] ||
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          action
        ][context.fieldSize][context.size]
      ) {
        disabled = true
      }
    } else if (actionSequence === 'secondSBActions') {
      if (
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          context.firstBBAction
        ]['secondSBAction'][action][context.size] ||
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          context.firstBBAction
        ]['secondSBAction'][action][context.fieldSize][context.size]
      ) {
        disabled = true
      }
    } else if (actionSequence === 'secondBBActions') {
      if (
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          context.firstBBAction
        ]['secondSBAction'][context.secondSBAction]['secondBBAction'][action][context.size] ||
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          context.firstBBAction
        ]['secondSBAction'][context.secondSBAction]['secondBBAction'][action][context.fieldSize][
          context.size
        ]
      ) {
        disabled = true
      }
    } else if (actionSequence === 'thirdSBActions') {
      if (
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          context.firstBBAction
        ]['secondSBAction'][context.secondSBAction]['secondBBAction'][context.secondBBAction][
          'thirdSBAction'
        ][action][context.size] ||
        disabledActions[context.type]['firstSBAction'][context.firstSBAction]['firstBBAction'][
          context.firstBBAction
        ]['secondSBAction'][context.secondSBAction]['secondBBAction'][context.secondBBAction][
          'thirdSBAction'
        ][action][context.fieldSize][context.size]
      ) {
        disabled = true
      }
    }
    return disabled
  }

  const renderActions = () => {
    let actionButtons = []
    let actions = context[actionSequence]

    for (let action of actions) {
      if (isDisabled(action)) {
        actionButtons.push(renderActionDisabled(action))
      } else {
        if (actionSequence === 'firstSBActions') {
          actionButtons.push(
            renderActionEnabled(action, {
              firstBBAction: 'none',
              secondSBAction: 'none',
              secondBBAction: 'none',
              thirdSBAction: 'none',
            }),
          )
        } else if (actionSequence === 'firstBBActions') {
          actionButtons.push(
            renderActionEnabled(action, {
              secondSBAction: 'none',
              secondBBAction: 'none',
              thirdSBAction: 'none',
            }),
          )
        } else if (actionSequence === 'secondSBActions') {
          actionButtons.push(
            renderActionEnabled(action, { secondBBAction: 'none', thirdSBAction: 'none' }),
          )
        } else if (actionSequence === 'secondBBActions') {
          actionButtons.push(renderActionEnabled(action, { thirdSBAction: 'none' }))
        } else if (actionSequence === 'thirdSBActions') {
          actionButtons.push(renderActionEnabled(action))
        }
      }
    }

    return actionButtons
  }

  return (
    <Grid container spacing={1} className={classes.section}>
      {renderActions()}
    </Grid>
  )
}
