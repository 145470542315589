import React from 'react'
import { Redirect } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useAuth0 } from '@auth0/auth0-react'
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import Loading from './loading'

const useStyles = makeStyles((theme) => ({
  landingWrapper: {
    background: 'rgb(37, 37, 37)',
    textAlign: 'center',
    paddingTop: '15%',
    width: '100vw',
    height: '100vh',
    spacing: 0,
    justify: 'space-around',
  },
  button: {
    alignItems: 'center',
    justify: 'center',
    width: '7%',
    height: '7%',
  },
}))

const theme = createTheme({
  palette: {
    background: 'rgb(37, 37, 37)',
    secondary: {
      main: 'rgb(181, 134, 22)',
    },
  },
})

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Button variant="contained" color="secondary" onClick={() => loginWithRedirect()}>
      Log In
    </Button>
  )
}

export default function Landing() {
  const { isAuthenticated, isLoading } = useAuth0()
  const classes = useStyles()

  if (isLoading) {
    return (
      <Grid container spacing={3} className={classes.landingWrapper}>
        <Loading />
      </Grid>
    )
  } else if (isAuthenticated) {
    return <Redirect to="/viewer" />
  } else {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Grid container spacing={3} className={classes.landingWrapper}>
            <Grid item xs={12}>
              <LoginButton className={classes.button} />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </div>
    )
  }
}
