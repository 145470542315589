import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RangeContext from '../contexts/RangeContext'

const useStyles = makeStyles((theme) => ({
  selectedSizeButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '3.5vw',
    borderRadius: '5px',
    boxShadow: 'inset 8px 8px 10px #946e12, inset -8px -8px 10px #d69e1a',
    background: 'rgb(181, 134, 22)',
    color: 'rgb(255, 255, 255)',
  },
  disabledSizeButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '3.5vw',
  },
  sizeButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '3.5vw',
    borderRadius: '5px',
    boxShadow: '5px 5px 10px #2b2b2b, -5px -5px 10px #434343',
    background: 'linear-gradient(145deg, #ffffff, #d6d6d6)',
    color: 'rgb(37, 37, 37)',
  },
  section: {
    paddingBottom: '2vh',
    paddingLeft: '0.5vw',
  },
}))

export default function StackSizes() {
  const [context, setContext] = useContext(RangeContext)

  const classes = useStyles()

  const renderSize = (size) => {
    let uiSize = size.substring(2) + 'bb'
    
    if (
      (context.type === 'vs3betJam' && size === 'BB7.5' && ['cEV', '75pct'].includes(context.fieldSize)) ||
      (context.type === 'vs4bet' && context.fieldSize !== 'cEV' && size === 'BB40') ||
      (context.type === 'vsSqueezeJam' && size === 'BB7.5' && ['cEV', '75pct'].includes(context.fieldSize)) ||
      (context.type === 'HU' && ['BB12.5', 'BB80'].indexOf(size) > -1)
    ) {
      return (
        <Grid item>
          <Button
            disabled
            className={classes.disabledSizeButton}
            variant="contained"
            onClick={() => setContext({ ...context, size })}
          >
            {uiSize}
          </Button>
        </Grid>
      )
    } else {
      return (
        <Grid item>
          <Button
            className={context.size === size ? classes.selectedSizeButton : classes.sizeButton}
            variant="contained"
            onClick={() => setContext({ ...context, size })}
          >
            {uiSize}
          </Button>
        </Grid>
      )
    }
  }

  if (context.type === 'vsOpen') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB7.5')}
        {renderSize('BB10')}
        {renderSize('BB12.5')}
        {renderSize('BB15')}
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'vs3bet') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'vsJam') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB2.5')}
        {renderSize('BB5')}
        {renderSize('BB7.5')}
        {renderSize('BB10')}
        {renderSize('BB12.5')}
        {renderSize('BB15')}
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'vs3betJam') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB7.5')}
        {renderSize('BB10')}
        {renderSize('BB12.5')}
        {renderSize('BB15')}
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'vs4bet') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'vs4betJam') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'squeeze') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB7.5')}
        {renderSize('BB10')}
        {renderSize('BB12.5')}
        {renderSize('BB15')}
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'vsSqueeze') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'vsSqueezeJam') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB7.5')}
        {renderSize('BB10')}
        {renderSize('BB12.5')}
        {renderSize('BB15')}
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else if (context.type === 'HU') {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB10')}
        {renderSize('BB12.5')}
        {renderSize('BB15')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={1} className={classes.section}>
        {renderSize('BB2.5')}
        {renderSize('BB5')}
        {renderSize('BB7.5')}
        {renderSize('BB10')}
        {renderSize('BB12.5')}
        {renderSize('BB15')}
        {renderSize('BB17.5')}
        {renderSize('BB20')}
        {renderSize('BB25')}
        {renderSize('BB30')}
        {renderSize('BB40')}
        {renderSize('BB50')}
        {renderSize('BB60')}
        {renderSize('BB80')}
        {renderSize('BB100')}
      </Grid>
    )
  }
}
