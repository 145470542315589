import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RangeContext from '../contexts/RangeContext'

const useStyles = makeStyles((theme) => ({
  selectedPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    boxShadow: 'inset 8px 8px 10px #946e12, inset -8px -8px 10px #d69e1a',
    background: 'rgb(181, 134, 22)',
    color: 'rgb(255, 255, 255)',
  },
  disabledPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
  },
  posButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    background: 'linear-gradient(145deg, #ffffff, #d6d6d6)',
    boxShadow: '5px 5px 10px #2b2b2b, -5px -5px 10px #434343',
    color: 'rgb(37, 37, 37)',
  },
  section: {
    paddingBottom: '2vh',
    paddingLeft: '0.5vw',
  },
}))

export default function Positions(props) {
  const [context, setContext] = useContext(RangeContext)

  const classes = useStyles()

  const {
    seat,
    comparison,
    comparisonSeat,
    positions,
    hposDefault,
    vposDefault,
    v2posDefault,
    defaultComparison,
    defaultComparisonSeat,
    disable,
  } = props

  const renderPosDisabled = (pos) => {
    let newContext = {}
    newContext[seat] = pos

    return (
      <Grid item>
        <Button
          disabled
          className={classes.disabledPosButton}
          variant="contained"
          onClick={() => setContext({ ...context, ...newContext })}
        >
          {pos}
        </Button>
      </Grid>
    )
  }

  const renderPosEnabled = (pos, newContext) => {
    newContext[seat] = pos

    return (
      <Grid item>
        <Button
          className={context[seat] === pos ? classes.selectedPosButton : classes.posButton}
          variant="contained"
          onClick={() => setContext({ ...context, ...newContext })}
        >
          {pos}
        </Button>
      </Grid>
    )
  }

  const renderPos = (pos) => {
    const positionOrder = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']
    let newContext = {}
    let defaultHpos, defaultVpos, defaultV2pos

    if (disable && disable.includes(pos)) {
      return renderPosDisabled(pos)
    }

    if (
      pos === 'EP' &&
      context.type === 'vsJam' &&
      context.size === 'BB15' &&
      context.fieldSize === '50pct'
    ) {
      return renderPosDisabled(pos)
    }

    if (hposDefault) {
      if (typeof hposDefault === 'string') {
        defaultHpos = hposDefault
      } else {
        if (hposDefault[pos]) {
          defaultHpos = hposDefault[pos]
          newContext['hpos'] = defaultHpos
        } else {
          defaultHpos = hposDefault.default
        }
      }
    }

    if (vposDefault) {
      if (typeof vposDefault === 'string') {
        defaultVpos = vposDefault
      } else {
        if (vposDefault[pos]) {
          defaultVpos = vposDefault[pos]
          newContext['vpos'] = defaultVpos
        } else {
          defaultVpos = vposDefault.default
        }
      }
    }

    if (v2posDefault) {
      if (typeof v2posDefault === 'string') {
        defaultV2pos = v2posDefault
      } else {
        if (v2posDefault[pos]) {
          defaultV2pos = v2posDefault[pos]
          newContext['v2pos'] = defaultV2pos
        } else {
          defaultV2pos = v2posDefault.default
        }
      }
    }

    if (defaultComparison === '>') {
      if (positionOrder.indexOf(pos) >= positionOrder.indexOf(context[defaultComparisonSeat])) {
        if (hposDefault) newContext['hpos'] = defaultHpos
        if (vposDefault) newContext['vpos'] = defaultVpos
        if (v2posDefault) newContext['v2pos'] = defaultV2pos
      }
    } else if (defaultComparison === '<') {
      if (positionOrder.indexOf(pos) <= positionOrder.indexOf(context[defaultComparisonSeat])) {
        if (hposDefault) newContext['hpos'] = defaultHpos
        if (vposDefault) newContext['vpos'] = defaultVpos
        if (v2posDefault) newContext['v2pos'] = defaultV2pos
      }
    }

    if (!comparison) {
      return renderPosEnabled(pos, newContext)
    } else if (comparison === '>') {
      if (positionOrder.indexOf(pos) > positionOrder.indexOf(context[comparisonSeat])) {
        return renderPosEnabled(pos, newContext)
      } else {
        return renderPosDisabled(pos)
      }
    } else {
      if (positionOrder.indexOf(pos) < positionOrder.indexOf(context[comparisonSeat])) {
        return renderPosEnabled(pos, newContext)
      } else {
        return renderPosDisabled(pos)
      }
    }
  }

  return (
    <Grid container spacing={1} className={classes.section}>
      {positions.includes('EP') && renderPos('EP')}
      {positions.includes('MP') && renderPos('MP')}
      {positions.includes('LJ') && renderPos('LJ')}
      {positions.includes('HJ') && renderPos('HJ')}
      {positions.includes('CO') && renderPos('CO')}
      {positions.includes('BTN') && renderPos('BTN')}
      {positions.includes('SB') && renderPos('SB')}
      {positions.includes('BB') && renderPos('BB')}
    </Grid>
  )
}
