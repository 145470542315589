import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  loading: {
    fontSize: '15vh',
    color: 'rgb(181, 134, 22)',
  },
}))

export default function Loading() {
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.loading}>
      <div>Loading ...</div>
    </Grid>
  )
}
