import React, { useContext } from 'react'
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import RangeContext from '../contexts/RangeContext'

const useStyles = makeStyles((theme) => ({
  situationsWrapper: {
    width: '9vw',
    background: 'rgb(37, 37, 37)',
    paddingTop: '7vh',
    maxWidth: '575px',
    minWidth: '130px',
  },
  title: {
    fontSize: 'max(17px, 1.25vw)',
    paddingLeft: 'max(7.6px, 0.5vw)',
  },
  buttonsWrapper: {
    justifyContent: 'center',
    paddingBottom: '2vh',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgb(37, 37, 37)',
    padding: '0.365vw',
    color: 'white',
    whiteSpace: 'nowrap',
    width: 'max(125px, 10vw)',
    borderRadius: '18px',
    boxShadow: '10px 10px 20px #171717,-10px -10px 20px #333333',
  },
  selectedTypeButton: {
    fontSize: 'max(10px, 0.75vw)',
    width: 'max(100px, 7.5vw)',
    borderRadius: '5px',
    boxShadow: 'inset 8px 8px 10px #946e12, inset -8px -8px 10px #d69e1a',
    background: 'rgb(181, 134, 22)',
    color: 'rgb(255, 255, 255)',
    minWidth: '100px',
  },
  typeButton: {
    fontSize: 'max(10px, 0.75vw)',
    width: 'max(100px, 7.5vw)',
    borderRadius: '5px',
    boxShadow: '5px 5px 10px #2b2b2b, -5px -5px 10px #434343',
    background: 'linear-gradient(145deg, #ffffff, #d6d6d6)',
    color: 'rgb(37, 37, 37)',
    minWidth: '100px',
  },
  disabledTypeButton: {
    fontSize: 'max(10px, 0.75vw)',
    width: 'max(100px, 7.5vw)',
  },
}))

const theme = createTheme({
  palette: {
    secondary: {
      main: 'rgb(181, 134, 22)',
    },
    action: {
      disabledBackground: 'rgb(92, 90, 86)',
      disabled: 'rgb(54, 50, 50)',
    },
  },
})

export default function Options() {
  const [context, setContext] = useContext(RangeContext)

  const classes = useStyles()

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container className={classes.situationsWrapper}>
        <Card className={classes.card}>
          <Grid container>
            <Grid item xs={3}>
              <p className={classes.title}>Situation</p>
            </Grid>
            <Grid container spacing={1} className={classes.buttonsWrapper}>
              <Grid item>
                <Button
                  className={
                    context.type === 'RFI' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() => setContext({ ...context, hpos: 'EP', vpos: 'EP', type: 'RFI' })}
                >
                  Raise first in
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vsOpen' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() => setContext({ ...context, hpos: 'MP', vpos: 'EP', type: 'vsOpen' })}
                >
                  Facing an open
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vs3bet' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({ ...context, size: 'BB25', hpos: 'EP', vpos: 'MP', type: 'vs3bet' })
                  }
                >
                  Facing a 3-bet
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vsJam' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({ ...context, size: 'BB10', hpos: 'MP', vpos: 'EP', type: 'vsJam' })
                  }
                >
                  Facing a jam
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vs3betJam' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB40',
                      hpos: 'EP',
                      vpos: 'BB',
                      type: 'vs3betJam',
                    })
                  }
                >
                  Facing 3-bet jam
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vs4bet' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB40',
                      hpos: 'MP',
                      vpos: 'EP',
                      type: 'vs4bet',
                    })
                  }
                >
                  Facing a 4-bet
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vs4betJam' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB40',
                      hpos: 'MP',
                      vpos: 'EP',
                      type: 'vs4betJam',
                    })
                  }
                >
                  Facing 4-bet Jam
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'squeeze' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB10',
                      hpos: 'LJ',
                      vpos: 'EP',
                      v2pos: 'MP',
                      type: 'squeeze',
                    })
                  }
                >
                  Squeezing
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vsSqueeze' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB40',
                      hpos: 'BTN',
                      vpos: 'SB',
                      v2pos: 'BB',
                      type: 'vsSqueeze',
                    })
                  }
                >
                  vs Squeeze
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'vsSqueezeJam' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB40',
                      hpos: 'BTN',
                      vpos: 'SB',
                      v2pos: 'BB',
                      type: 'vsSqueezeJam',
                    })
                  }
                >
                  vs Squeeze Jam
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'bvb' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB10',
                      type: 'bvb',
                      firstSBAction: 'none',
                      firstBBAction: 'none',
                      secondSBAction: 'none',
                      secondBBAction: 'none',
                    })
                  }
                >
                  Blind vs Blind
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={
                    context.type === 'HU' ? classes.selectedTypeButton : classes.typeButton
                  }
                  variant="contained"
                  onClick={() =>
                    setContext({
                      ...context,
                      size: 'BB10',
                      type: 'HU',
                      firstSBAction: 'none',
                      firstBBAction: 'none',
                      secondSBAction: 'none',
                      secondBBAction: 'none',
                    })
                  }
                >
                  Heads Up
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </MuiThemeProvider>
  )
}
