import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import RangeContext from '../contexts/RangeContext'

const useStyles = makeStyles((theme) => ({
  raiseCell: {
    background: 'rgb(217, 160, 102)',
    height: '4vh',
    width: 'max(100px, 7.5vw)',
    fontSize: 'max(10px, 0.75vw)',
    lineHeight: '4vh',
    textAlign: 'center',
    minWidth: '100px',
  },
  jamCell: {
    background: 'rgb(217, 87, 99)',
    height: '4vh',
    width: 'max(100px, 7.5vw)',
    fontSize: 'max(10px, 0.75vw)',
    lineHeight: '4vh',
    textAlign: 'center',
    minWidth: '100px',
  },
  callCell: {
    background: 'rgb(251, 242, 54)',
    height: '4vh',
    width: 'max(100px, 7.5vw)',
    fontSize: 'max(10px, 0.75vw)',
    lineHeight: '4vh',
    textAlign: 'center',
    minWidth: '100px',
  },
  foldCell: {
    height: '4vh',
    width: 'max(100px, 7.5vw)',
    fontSize: 'max(10px, 0.75vw)',
    lineHeight: '4vh',
    textAlign: 'center',
    minWidth: '100px',
  },
  situationsWrapper: {
    width: '9vw',
    background: 'rgb(37, 37, 37)',
    paddingTop: '7vh',
    maxWidth: '575px',
    minWidth: '130px',
  },
  title: {
    fontSize: 'max(17px, 1.25vw)',
    paddingLeft: 'max(7.6px, 0.5vw)',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgb(37, 37, 37)',
    padding: '0.365vw',
    color: 'white',
    whiteSpace: 'nowrap',
    width: 'max(125px, 10vw)',
    borderRadius: '18px',
    boxShadow: '10px 10px 20px #171717,-10px -10px 20px #333333',
  },
  keyWrapper: {
    justifyContent: 'center',
    paddingBottom: '2vh',
  },
}))

export default function ViewerKey() {
  const [context] = useContext(RangeContext)

  const classes = useStyles()

  let raiseSize
  let callLabel = 'Call'
  let size = parseInt(context.size.replace('BB', ''))
  let { callFrequency, raiseFrequency, jamFrequency, foldFrequency } = context

  if (context.type === 'RFI') {
    callLabel = 'Limp'

    if (context.hpos === 'SB') {
      if (size <= 12.5) {
        raiseSize = '2.5x'
      } else if (size <= 20) {
        raiseSize = '2.75x'
      } else if (size === 25) {
        raiseSize = '3.25x'
      } else {
        raiseSize = '3.5x'
      }
    } else {
      if (size <= 30) {
        raiseSize = '2x'
      } else if (size <= 60) {
        raiseSize = '2.3x'
      } else {
        raiseSize = '2.5x'
      }
    }
  } else if (context.type === 'vsOpen') {
    if (context.hpos === 'BB') {
      if (size <= 20) {
        raiseSize = '3x'
      } else if (size <= 25) {
        raiseSize = '3.5x'
      } else if (size <= 30) {
        raiseSize = '3.75x'
      } else if (size <= 60) {
        raiseSize = '4x'
      } else if (size <= 80) {
        raiseSize = '4.2x'
      } else {
        raiseSize = '4.5x'
      }
    } else if (context.hpos === 'SB') {
      if (size <= 20) {
        raiseSize = '3x'
      } else if (size <= 25) {
        raiseSize = '3.25x'
      } else if (size <= 60) {
        raiseSize = '3.5x'
      } else if (size <= 80) {
        raiseSize = '3.8x'
      } else {
        raiseSize = '4x'
      }
    } else {
      if (size <= 20) {
        raiseSize = '2.5x'
      } else if (size <= 30) {
        raiseSize = '2.75x'
      } else if (size <= 60) {
        raiseSize = '3x'
      } else if (size <= 80) {
        raiseSize = '3.1x'
      } else {
        raiseSize = '3.5x'
      }
    }
  } else if (context.type === 'vs3bet') {
    if (['SB', 'BB'].includes(context.vpos)) {
      if (size <= 40) {
        raiseSize = '2.49x'
      } else if (size <= 50) {
        raiseSize = '2.1x'
      } else if (size <= 60) {
        raiseSize = '2.49x'
      } else if (size <= 80) {
        raiseSize = '2.6x'
      } else {
        raiseSize = '2.75x'
      }
    } else {
      if (size <= 40) {
        raiseSize = '2.17x'
      } else if (size <= 50) {
        raiseSize = '2.02x'
      } else if (size <= 60) {
        raiseSize = '2.19x'
      } else if (size <= 80) {
        raiseSize = '2.1x'
      } else {
        raiseSize = '2.3x'
      }
    }
  } else if (context.type === 'squeeze') {
    if (context.hpos === 'BB') {
      if (size <= 20) {
        raiseSize = '4x'
      } else if (size <= 25) {
        raiseSize = '4.5x'
      } else if (size <= 30) {
        raiseSize = '4.75x'
      } else if (size <= 60) {
        raiseSize = '5x'
      } else if (size <= 80) {
        raiseSize = '5.2x'
      } else {
        raiseSize = '5.5x'
      }
    } else if (context.hpos === 'SB') {
      if (size <= 20) {
        raiseSize = '4x'
      } else if (size <= 25) {
        raiseSize = '4.25x'
      } else if (size <= 60) {
        raiseSize = '4.5x'
      } else if (size <= 80) {
        raiseSize = '4.8x'
      } else {
        raiseSize = '5x'
      }
    } else {
      if (size <= 20) {
        raiseSize = '3.5x'
      } else if (size <= 30) {
        raiseSize = '3.75x'
      } else if (size <= 60) {
        raiseSize = '4x'
      } else if (size <= 80) {
        raiseSize = '4.1x'
      } else {
        raiseSize = '4.5x'
      }
    }
  } else if (context.type === 'vs Squeeze') {
    if (context.hpos === 'BB') {
      if (size <= 20) {
        raiseSize = '4x'
      } else if (size <= 25) {
        raiseSize = '4.5x'
      } else if (size <= 30) {
        raiseSize = '4.75x'
      } else if (size <= 60) {
        raiseSize = '5x'
      } else if (size <= 80) {
        raiseSize = '5.2x'
      } else {
        raiseSize = '5.5x'
      }
    } else if (context.hpos === 'SB') {
      if (size <= 20) {
        raiseSize = '4x'
      } else if (size <= 25) {
        raiseSize = '4.25x'
      } else if (size <= 60) {
        raiseSize = '4.5x'
      } else if (size <= 80) {
        raiseSize = '4.8x'
      } else {
        raiseSize = '5x'
      }
    } else {
      if (size <= 20) {
        raiseSize = '3.5x'
      } else if (size <= 30) {
        raiseSize = '3.75x'
      } else if (size <= 60) {
        raiseSize = '4x'
      } else if (size <= 80) {
        raiseSize = '4.1x'
      } else {
        raiseSize = '4.5x'
      }
    }
  } else if (context.type === 'bvb') {
    if (context.firstSBAction === 'none') {
      callLabel = 'Limp'
    }
    if (context.secondSBAction !== 'none') {
      if (size <= 80) {
        raiseSize = '2x'
      } else {
        raiseSize = '2.3x'
      }
    } else if (context.firstBBAction !== 'none') {
      if (context.firstSBAction === 'call') {
        if (size <= 80) {
          raiseSize = '3x'
        } else {
          raiseSize = '3.42x'
        }
      } else {
        if (size <= 80) {
          raiseSize = '2.3x'
        } else {
          raiseSize = '2.75x'
        }
      }
    } else if (context.firstSBAction !== 'none') {
      if (context.firstSBAction === 'call') {
        callLabel = 'Check'
        if (size <= 12.5) {
          raiseSize = '2.5x'
        } else if (size <= 20) {
          raiseSize = '3x'
        } else {
          raiseSize = '3.5x'
        }
      } else {
        if (size <= 25) {
          raiseSize = '2.75x'
        } else {
          raiseSize = '3x'
        }
      }
    } else {
      if (size <= 12.5) {
        raiseSize = '2.5x'
      } else if (size <= 20) {
        raiseSize = '2.75x'
      } else if (size <= 25) {
        raiseSize = '3.25x'
      } else {
        raiseSize = '3.5x'
      }
    }
  } else if (context.type === 'HU') {
    if (context.firstSBAction === 'none') {
      callLabel = 'Limp'
    }
    if (context.secondSBAction !== 'none') {
      raiseSize = '2.67x'
    } else if (context.firstBBAction !== 'none') {
      if (context.firstSBAction === 'call') {
        raiseSize = '3x'
      } else {
        raiseSize = '2.33x'
      }
    } else if (context.firstSBAction !== 'none') {
      if (context.firstSBAction === 'call') {
        callLabel = 'Check'
        if (size < 15) {
          raiseSize = '2x'
        } else if (size === 15) {
          raiseSize = '2.5x'
        } else {
          raiseSize = '3.5x'
        }
      } else {
        raiseSize = '3x'
      }
    } else {
      if (size < 40) {
        raiseSize = '2x'
      } else {
        raiseSize = '2.25x'
      }
    }
  } else {
    if (size <= 50) {
      raiseSize = '2.09x'
    } else if (size <= 60) {
      raiseSize = '2.49x'
    } else if (size <= 80) {
      raiseSize = '2.61x'
    } else {
      raiseSize = '2.75x'
    }
  }

  const showCall = () => {
    if (context.rangeHasCall) {
      return (
        <Grid item>
          <Paper elevation={4} className={classes.callCell}>
            {callLabel} - {callFrequency}%
          </Paper>
        </Grid>
      )
    }
    return null
  }

  const showRaise = () => {
    if (context.rangeHasRaise) {
      return (
        <Grid item>
          <Paper elevation={4} className={classes.raiseCell}>
            Raise ({raiseSize}) - {raiseFrequency}%
          </Paper>
        </Grid>
      )
    }
    return null
  }

  const showJam = () => {
    if (context.rangeHasJam) {
      return (
        <Grid item>
          <Paper elevation={4} className={classes.jamCell}>
            Jam - {jamFrequency}%
          </Paper>
        </Grid>
      )
    }
    return null
  }

  return (
    <Grid container className={classes.situationsWrapper}>
      <Card className={classes.card}>
        <Grid container>
          <Grid item xs={3}>
            <p className={classes.title}>Frequency</p>
          </Grid>
          <Grid container spacing={1} className={classes.keyWrapper}>
            <Grid item>
              <Paper elevation={4} className={classes.foldCell}>
                Fold - {foldFrequency}%
              </Paper>
            </Grid>
            {showCall()}
            {showRaise()}
            {showJam()}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}
