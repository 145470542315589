import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RangeContext from '../contexts/RangeContext'

const useStyles = makeStyles((theme) => ({
  selectedPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    boxShadow: 'inset 8px 8px 10px #946e12, inset -8px -8px 10px #d69e1a',
    background: 'rgb(181, 134, 22)',
    color: 'rgb(255, 255, 255)',
  },
  disabledPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
  },
  posButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    background: 'linear-gradient(145deg, #ffffff, #d6d6d6)',
    boxShadow: '5px 5px 10px #2b2b2b, -5px -5px 10px #434343',
    color: 'rgb(37, 37, 37)',
  },
  section: {
    paddingBottom: '2vh',
    paddingLeft: '0.5vw',
  },
}))

export default function BlindActions(props) {
  const [context, setContext] = useContext(RangeContext)

  const classes = useStyles()

  const { actionSequence, blindAction } = props

  const renderActionDisabled = (action) => {
    return (
      <Grid item>
        <Button disabled className={classes.disabledPosButton} variant="contained">
          {action}
        </Button>
      </Grid>
    )
  }

  const renderActionEnabled = (action, newContext = {}) => {
    let label
    newContext[blindAction] = action

    if (action === 'call') {
      label = 'Limp'
    } else {
      label = action
    }

    return (
      <Grid item key={action}>
        <Button
          className={
            context[blindAction] === action ? classes.selectedPosButton : classes.posButton
          }
          variant="contained"
          onClick={() => setContext({ ...context, ...newContext })}
        >
          {label}
        </Button>
      </Grid>
    )
  }

  const renderActions = () => {
    let actionButtons = []
    let actions = context[actionSequence]

    for (let action of actions) {
      if (actionSequence === 'firstSBActions') {
        if (
          (['BB100', 'BB40', 'BB50', 'BB60', 'BB80'].indexOf(context.size) > -1 ||
            (['BB20', 'BB25', 'BB30'].indexOf(context.size) > -1 && context.type === 'HU')) &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (['BB10', 'BB12.5'].indexOf(context.size) > -1 && action === 'raise') {
          actionButtons.push(renderActionDisabled(action))
        } else {
          actionButtons.push(
            renderActionEnabled(action, {
              firstBBAction: 'none',
              secondSBAction: 'none',
              secondBBAction: 'none',
              thirdSBAction: 'none',
            }),
          )
        }
      } else if (actionSequence === 'firstBBActions') {
        if (['BB100', 'BB40', 'BB60', 'BB80'].indexOf(context.size) > -1 && action === 'jam') {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          (['BB50'].indexOf(context.size) > -1 ||
            (['BB25', 'BB30'].indexOf(context.size) > -1 && context.type === 'HU')) &&
          context.firstSBAction === 'call' &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          ['BB30', 'BB50'].indexOf(context.size) > -1 &&
          context.type === 'HU' &&
          context.firstSBAction === 'raise' &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.firstSBAction === 'jam' && action !== 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          (['BB15'].indexOf(context.size) > -1 ||
            (['BB25'].indexOf(context.size) > -1 && context.type === 'bvb')) &&
          context.firstSBAction === 'raise' &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          context.type === 'bvb' &&
          ['BB20'].indexOf(context.size) > -1 &&
          context.firstSBAction === 'raise' &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          context.type === 'HU' &&
          ['BB10'].indexOf(context.size) > -1 &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.firstSBAction === 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else {
          actionButtons.push(
            renderActionEnabled(action, {
              secondSBAction: 'none',
              secondBBAction: 'none',
              thirdSBAction: 'none',
            }),
          )
        }
      } else if (actionSequence === 'secondSBActions') {
        if (['BB100', 'BB80'].indexOf(context.size) > -1 && action === 'jam') {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.firstBBAction === 'jam' && action !== 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          ['BB40', 'BB50'].indexOf(context.size) > -1 &&
          context.type === 'HU' &&
          context.firstBBAction === 'raise' &&
          context.firstSBAction === 'call' &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          ['BB60'].indexOf(context.size) > -1 &&
          context.type === 'HU' &&
          context.firstBBAction === 'raise' &&
          context.firstSBAction === 'raise' &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          (['BB30', 'BB40'].indexOf(context.size) > -1 ||
            (['BB20', 'BB25'].indexOf(context.size) > -1 && context.type === 'HU') ||
            (['BB50', 'BB60'].indexOf(context.size) > -1 && context.type === 'bvb')) &&
          context.firstSBAction === 'raise' &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          (['BB10', 'BB12.5', 'BB15', 'BB20', 'BB25'].indexOf(context.size) > -1 ||
            (['BB30'].indexOf(context.size) > -1 && context.type === 'HU') ||
            (['BB40'].indexOf(context.size) > -1 && context.type === 'bvb')) &&
          context.firstSBAction === 'call' &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.firstBBAction === 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else {
          actionButtons.push(
            renderActionEnabled(action, { secondBBAction: 'none', thirdSBAction: 'none' }),
          )
        }
      } else if (actionSequence === 'secondBBActions') {
        if (
          ['BB100'].indexOf(context.size) > -1 &&
          context.firstSBAction === 'call' &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          ['BB30', 'BB50', 'BB60'].indexOf(context.size) > -1 &&
          context.firstSBAction === 'call' &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.secondSBAction === 'jam' && action !== 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          ['BB100', 'BB80'].indexOf(context.size) > -1 &&
          context.firstSBAction === 'raise' &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          ['BB40', 'BB50', 'BB60'].indexOf(context.size) > -1 &&
          context.type === 'HU' &&
          context.secondSBAction === 'raise' &&
          action === 'raise'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.secondSBAction === 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else {
          actionButtons.push(renderActionEnabled(action, { thirdSBAction: 'none' }))
        }
      } else if (actionSequence === 'thirdSBActions') {
        if (
          ['BB100'].indexOf(context.size) > -1 &&
          context.firstSBAction === 'raise' &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (
          ['BB30', 'BB50', 'BB60'].indexOf(context.size) > -1 &&
          context.firstSBAction === 'call' &&
          action === 'jam'
        ) {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.secondBBAction === 'jam' && action !== 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else if (context.secondBBAction === 'none') {
          actionButtons.push(renderActionDisabled(action))
        } else {
          actionButtons.push(renderActionEnabled(action))
        }
      } else {
        actionButtons.push(renderActionDisabled(action))
      }
    }

    return actionButtons
  }

  return (
    <Grid container spacing={1} className={classes.section}>
      {renderActions()}
    </Grid>
  )
}
