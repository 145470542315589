import './App.css'
import React, { useState } from 'react'
import ICM_Dashboard from './components/icm_dashboard'
import Dashboard from './components/dashboard'
import Landing from './components/landing'
import RangeContext from './contexts/RangeContext'
import { Auth0Provider } from '@auth0/auth0-react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

function App() {
  const [context, setContext] = useState({
    fieldSize: 'cEV',
    type: 'RFI',
    size: 'BB10',
    hpos: 'EP',
    vpos: 'EP',
    firstSBActions: ['none', 'call', 'raise', 'jam'],
    firstSBAction: 'none',
    firstBBActions: ['none', 'raise', 'jam'],
    firstBBAction: 'none',
    secondSBActions: ['none', 'raise', 'jam'],
    secondSBAction: 'none',
    secondBBActions: ['none', 'raise', 'jam'],
    secondBBAction: 'none',
    thirdSBActions: ['none', 'jam'],
    thirdSBAction: ['none'],
    totalStartingHands: 1326,
    callFrequency: 0,
    raiseFrequency: 0,
    jamFrequency: 0,
    foldFrequency: 0,
  })

  let domain
  let clientId
  let redirectUri

  if (process.env.REACT_APP_CONTEXT === 'branch-deploy') {
    domain = process.env.REACT_APP_AUTH0_DOMAIN_STAGE
    clientId = process.env.REACT_APP_AUTH0_CLIENT_ID_STAGE
    redirectUri = `${process.env.REACT_APP_DEPLOY_URL}/viewer`
  } else {
    domain = process.env.REACT_APP_AUTH0_DOMAIN
    clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
    redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI
  }

  return (
    <Auth0Provider domain={domain} clientId={clientId} redirectUri={redirectUri}>
      <RangeContext.Provider value={[context, setContext]}>
        <div>
          <Router>
            <div>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/viewer" component={ICM_Dashboard} />
                <Route exact path="/icm-viewer" component={ICM_Dashboard} />
              </Switch>
            </div>
          </Router>
        </div>
      </RangeContext.Provider>
    </Auth0Provider>
  )
}

export default App
