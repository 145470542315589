import React from 'react'
import { Redirect } from 'react-router-dom'
import Viewer from './icm_viewer'
import Options from './icm_options'
import Situations from './icm_situations'
import Grid from '@material-ui/core/Grid'
import ViewerKey from './icm_viewerKey'
import Loading from './loading'
import { useAuth0 } from '@auth0/auth0-react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    height: '80vh',
    width: '100vw',
    marginTop: '5vh',
    minWidth: '1308px',
  },
  loadingWrapper: {
    height: '80vh',
    width: '100vw',
    marginTop: '5vh',
    textAlign: 'center',
    paddingTop: '10vh',
  },
  viewerWrapper: {
    width: '50%',
  },
  keyWrapper: {
    marginInline: 'auto',
    marginTop: '0vh',
  },
  optionsWrapper: {
    display: 'flex',
    width: '50%',
    minWidth: 'calc(100% - (calc(730px + 4vw))',
  },
}))

export default function Home() {
  const { isAuthenticated, isLoading } = useAuth0()
  const classes = useStyles()

  if (isLoading) {
    return (
      <Grid container spacing={3} className={classes.loadingWrapper}>
        <Loading />
      </Grid>
    )
  } else if (!isAuthenticated) {
    return <Redirect to="/" />
  } else {
    return (
      <div className="MTT Range Viewer">
        <Grid container spacing={1} className={classes.pageWrapper}>
          <Grid container item xs={6}>
            <Grid item xs={12} className={classes.viewerWrapper}>
              <Viewer />
            </Grid>
          </Grid>
          <Grid container item xs={6} className={classes.optionsWrapper}>
            <Grid item xs={3}>
              <Situations />
              <ViewerKey />
            </Grid>
            <Grid item xs={9}>
              <Options />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}
