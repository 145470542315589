import React, { useContext } from 'react'
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RangeContext from '../contexts/RangeContext'
import StackSizes from './icm_stackSizes'
import BlindActions from './icm_blindActions'
import Positions from './icm_positions'

const useStyles = makeStyles((theme) => ({
  optionsWrapper: {
    width: '25vw',
    background: 'rgb(37, 37, 37)',
    paddingTop: '7vh',
    maxWidth: '575px',
    minWidth: '300px',
  },
  title: {
    fontSize: 'max(17px, 1vw)',
    paddingLeft: '0.5vw',
  },
  card: {
    background: 'rgb(37, 37, 37)',
    padding: '0.365vw',
    paddingBottom: '15px',
    color: 'white',
    whiteSpace: 'nowrap',
    width: '25vw',
    borderRadius: '18px',
    boxShadow: '10px 10px 20px #171717,-10px -10px 20px #333333',
    minWidth: '300px',
  },
  selectedPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    boxShadow: 'inset 8px 8px 10px #946e12, inset -8px -8px 10px #d69e1a',
    background: 'rgb(181, 134, 22)',
    color: 'rgb(255, 255, 255)',
  },
  disabledPosButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
  },
  posButton: {
    fontSize: 'max(9px, 0.55vw)',
    width: '5vw',
    borderRadius: '5px',
    background: 'linear-gradient(145deg, #ffffff, #d6d6d6)',
    boxShadow: '5px 5px 10px #2b2b2b, -5px -5px 10px #434343',
    color: 'rgb(37, 37, 37)',
  },
}))

const theme = createTheme({
  overrides: {
    MuiRadio: {
      root: {
        color: 'white',
      },
    },
  },
  palette: {
    secondary: {
      main: 'rgb(181, 134, 22)',
    },
    action: {
      disabledBackground: 'rgb(92, 90, 86)',
      disabled: 'rgb(54, 50, 50)',
    },
  },
})

export default function Options() {
  const [context, setContext] = useContext(RangeContext)

  const classes = useStyles()

  const changeFieldSize = (e) => {
    setContext({
      ...context,
      fieldSize: e.target.value,
    })
  }

  const renderRadio = () => {
    return (
      <FormControl>
        <RadioGroup
          row
          name="field-size-radio-group"
          value={context.fieldSize}
          onChange={changeFieldSize}
        >
          <FormControlLabel
            labelPlacement="start"
            value="cEV"
            control={<Radio size="small" />}
            label="cEV"
          />
          <FormControlLabel
            labelPlacement="start"
            value="75pct"
            control={<Radio size="small" />}
            label="75%"
          />
          <FormControlLabel
            labelPlacement="start"
            value="50pct"
            control={<Radio size="small" />}
            label="50%"
          />
          <FormControlLabel
            labelPlacement="start"
            value="25pct"
            control={<Radio size="small" />}
            label="25%"
          />
        </RadioGroup>
      </FormControl>
    )
  }

  const renderTitle = (title) => {
    return (
      <Grid container>
        <Grid item xs={3}>
          <p className={classes.title}>{title}</p>
        </Grid>
      </Grid>
    )
  }

  const renderPositions = () => {
    if (context.type === 'RFI') {
      let positions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']

      return (
        <div>
          {renderTitle('My position')}
          <Positions positions={positions} seat="hpos" />
        </div>
      )
    } else if (context.type === 'vsOpen') {
      let vPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      let hPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']

      return (
        <div>
          {renderTitle("Villain's position")}
          <Positions
            positions={vPositions}
            seat="vpos"
            hposDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="hpos"
          />
          {renderTitle('My position')}
          <Positions positions={hPositions} seat="hpos" comparison=">" comparisonSeat="vpos" />
        </div>
      )
    } else if (context.type === 'vs3bet') {
      let vPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']
      let hPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']

      return (
        <div>
          {renderTitle('My position')}
          <Positions
            positions={hPositions}
            seat="hpos"
            vposDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="vpos"
          />
          {renderTitle("Villain's position")}
          <Positions positions={vPositions} seat="vpos" comparison=">" comparisonSeat="hpos" />
        </div>
      )
    } else if (context.type === 'vsJam') {
      const vPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      const hPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']

      return (
        <div>
          {renderTitle("Villain's position")}
          <Positions
            positions={vPositions}
            seat="vpos"
            hposDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="hpos"
          />
          {renderTitle('My position')}
          <Positions positions={hPositions} seat="hpos" comparison=">" comparisonSeat="vpos" />
        </div>
      )
    } else if (context.type === 'vs3betJam') {
      let vPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']
      let hPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      let vDisable, hDisable
      let vposDefault = 'BB'

      return (
        <div>
          {renderTitle('My position')}
          <Positions
            positions={hPositions}
            seat="hpos"
            vposDefault={vposDefault}
            defaultComparison=">"
            defaultComparisonSeat="vpos"
            disable={hDisable}
          />
          {renderTitle("Villain's position")}
          <Positions
            positions={vPositions}
            seat="vpos"
            comparison=">"
            comparisonSeat="hpos"
            disable={vDisable}
          />
        </div>
      )
    } else if (context.type === 'vs4bet') {
      let vPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      let hPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']

      return (
        <div>
          {renderTitle("Villain's position")}
          <Positions
            positions={vPositions}
            seat="vpos"
            hposDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="hpos"
          />
          {renderTitle('My position')}
          <Positions positions={hPositions} seat="hpos" comparison=">" comparisonSeat="vpos" />
        </div>
      )
    } else if (context.type === 'vs4betJam') {
      let vPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      let hPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']

      return (
        <div>
          {renderTitle("Villain's position")}
          <Positions
            positions={vPositions}
            seat="vpos"
            hposDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="hpos"
          />
          {renderTitle('My position')}
          <Positions positions={hPositions} seat="hpos" comparison=">" comparisonSeat="vpos" />
        </div>
      )
    } else if (context.type === 'squeeze') {
      let oRPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      let flatPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB']
      let hPositions = ['LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']

      return (
        <div>
          {renderTitle("Original Raiser's position")}
          <Positions
            positions={oRPositions}
            seat="vpos"
            v2posDefault="SB"
            hposDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="v2pos"
          />
          {renderTitle("Caller's position")}
          <Positions
            positions={flatPositions}
            seat="v2pos"
            comparison=">"
            comparisonSeat="vpos"
            hposDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="hpos"
          />
          {renderTitle('My position')}
          <Positions positions={hPositions} seat="hpos" comparison=">" comparisonSeat="v2pos" />
        </div>
      )
    } else if (context.type === 'vsSqueeze') {
      let hPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      let flatPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB']
      let raisePositions = ['LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']

      return (
        <div>
          {renderTitle("My position")}
          <Positions
            positions={hPositions}
            seat="hpos"
            vposDefault="SB"
            v2posDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="vpos"
          />
          {renderTitle("Caller's position")}
          <Positions
            positions={flatPositions}
            seat="vpos"
            comparison=">"
            comparisonSeat="hpos"
            v2posDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="hpos"
          />
          {renderTitle("Squeezer's position")}
          <Positions
            positions={raisePositions}
            seat="v2pos"
            comparison=">"
            comparisonSeat="vpos"
          />
        </div>
      )
    } else if (context.type === 'vsSqueezeJam') {
      let hPositions = ['EP', 'MP', 'LJ', 'HJ', 'CO', 'BTN']
      let flatPositions = ['MP', 'LJ', 'HJ', 'CO', 'BTN', 'SB']
      let raisePositions = ['LJ', 'HJ', 'CO', 'BTN', 'SB', 'BB']

      return (
        <div>
          {renderTitle("My position")}
          <Positions
            positions={hPositions}
            seat="hpos"
            vposDefault="SB"
            v2posDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="vpos"
          />
          {renderTitle("Caller's position")}
          <Positions
            positions={flatPositions}
            seat="vpos"
            comparison=">"
            comparisonSeat="hpos"
            v2posDefault="BB"
            defaultComparison=">"
            defaultComparisonSeat="hpos"
          />
          {renderTitle("Squeezer's position")}
          <Positions
            positions={raisePositions}
            seat="v2pos"
            comparison=">"
            comparisonSeat="vpos"
          />
        </div>
      )
    } else if (context.type === 'bvb') {
      return (
        <div>
          {renderTitle('First SB Action')}
          <BlindActions actionSequence="firstSBActions" blindAction="firstSBAction" />
          {renderTitle('First BB Action')}
          <BlindActions actionSequence="firstBBActions" blindAction="firstBBAction" />
          {renderTitle('Second SB Action')}
          <BlindActions actionSequence="secondSBActions" blindAction="secondSBAction" />
          {renderTitle('Second BB Action')}
          <BlindActions actionSequence="secondBBActions" blindAction="secondBBAction" />
          {renderTitle('Third SB Action')}
          <BlindActions actionSequence="thirdSBActions" blindAction="thirdSBAction" />
        </div>
      )
    } else if (context.type === 'HU') {
      return (
        <div>
          {renderTitle('First SB Action')}
          <BlindActions actionSequence="firstSBActions" blindAction="firstSBAction" />
          {renderTitle('First BB Action')}
          <BlindActions actionSequence="firstBBActions" blindAction="firstBBAction" />
          {renderTitle('Second SB Action')}
          <BlindActions actionSequence="secondSBActions" blindAction="secondSBAction" />
          {renderTitle('Second BB Action')}
          <BlindActions actionSequence="secondBBActions" blindAction="secondBBAction" />
          {renderTitle('Third SB Action')}
          <BlindActions actionSequence="thirdSBActions" blindAction="thirdSBAction" />
        </div>
      )
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container className={classes.optionsWrapper}>
        <Card className={classes.card}>
          {renderRadio()}
          {renderTitle('Stack size')}
          <StackSizes />
          {renderPositions()}
        </Card>
      </Grid>
    </MuiThemeProvider>
  )
}
